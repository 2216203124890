import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography
} from "@material-ui/core";
import {Line} from "react-chartjs-2";
import React, {useContext, useEffect} from "react";
import themeColors from "../../../assets/theme/colors";
import {FormattedMessage, useIntl} from "react-intl";
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";
import moment from "moment/moment";
import {SettingsContext} from "../../../contexts/Settings";
import {useNavigate} from "react-router-dom";


export default function Usage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const initialStats = {
    processing: {
      labels: [],
      datasets: [
        {
          label: intl.formatMessage({id: "usage.processing.integration.label", defaultMessage: "Integrations"}),
          data: [],
          borderColor: themeColors.primary.main,
        },
        {
          label: intl.formatMessage({id: "usage.processing.events.label", defaultMessage: "Actions"}),
          data: [],
          borderColor: themeColors.secondary.main,
        },
        {
          label: intl.formatMessage({id: "usage.processing.exports.label", defaultMessage: "Exports"}),
          data: [],
          borderColor: themeColors.warning.main,
        },
        {
          label: intl.formatMessage({id: "usage.processing.imports.label", defaultMessage: "Imports"}),
          data: [],
          borderColor: themeColors.success.main,
        },
      ],
    },
    storage: {
      labels: [],
      datasets: [
        {
          label: intl.formatMessage({id: "usage.storage.values.label", defaultMessage: "Values"}),
          data: [],
          borderColor: themeColors.primary.main,
        },
        {
          label: intl.formatMessage({id: "usage.storage.fields.label", defaultMessage: "Fields"}),
          data: [],
          borderColor: themeColors.secondary.main,
        },
      ],
    }
  };
  const [isLoading, setIsLoading] = React.useState(null);
  const [usage, setUsage] = React.useState({storage: 0, process: {total: 0, percent: 0, limit: 0}});
  const [subStorage, setSubStorage] = React.useState({id: 0, renewed_at: null});
  const [subProcess, setSubProcess] = React.useState({id: 0, renewed_at: null});
  const [stats, setStats] = React.useState(initialStats);
  const {siteSubscriptions, refresh} = useContext(SettingsContext);
  const client = new graphQLApi(useAuthDispatch());
  useEffect(() => {
    refresh();
    setIsLoading(false);
  }, []);
  useEffect(() => {
    if (isLoading === false && siteSubscriptions?.length) {
      setIsLoading(true);
      client.query('{' +
        'subscriptions{id type_id type title feature_list automation_id limit price price_currency}' +
        'statistics(filter:{created_at_gte:"' + moment().subtract(1, 'month').format('YYYY-MM-DD 00:00:00') + '"}){data{stats_type counter created_at}}' +
        '}').then(r => {
        let _stats = initialStats;
        let startDate = moment().subtract(1, "month");
        let endDate = moment().add(1, "day");

        let subS = siteSubscriptions.find(s => s.type_id === '82')
        setSubStorage(subS);
        let subP = siteSubscriptions.find(s => s.type_id === '83')
        setSubProcess(subP);

        while (startDate.isBefore(endDate)) {
          if (moment(subP.renewed_at).isSameOrBefore(startDate)) {
            _stats.processing.labels.push(startDate.format('YYYY-MM-DD'));
            _stats.processing.datasets[0].data.push(0);
            _stats.processing.datasets[1].data.push(0);
            _stats.processing.datasets[2].data.push(0);
            _stats.processing.datasets[3].data.push(0);
          }
          if (moment(subS.renewed_at).isSameOrBefore(startDate)) {
            _stats.storage.labels.push(startDate.format('YYYY-MM-DD'));
            _stats.storage.datasets[0].data.push(0);
            _stats.storage.datasets[1].data.push(0);
          }
          startDate.add(1, "day");
        }
        let usedStorage = 0;
        let usedProcessing = 0;
        if (r && r.hasOwnProperty('statistics')) {
          r.statistics.data.forEach(s => {
            let date = moment(s.created_at).format('YYYY-MM-DD');
            let dateIdx = _stats.processing.labels.findIndex(l => l === date);
            if (dateIdx === -1) return;
            // eslint-disable-next-line default-case
            switch (s.stats_type) {
              case 'App\\Jobs\\ImportJob':
                _stats.processing.datasets[3].data[dateIdx] += s.counter
                usedProcessing += s.counter;
                break;
              case 'App\\Jobs\\ExportJob':
                _stats.processing.datasets[2].data[dateIdx] += s.counter
                usedProcessing += s.counter;
                break;
              case 'App\\Models\\Channel':
                _stats.processing.datasets[0].data[dateIdx] += s.counter
                usedProcessing += s.counter;
                break;
              case 'App\\Models\\Event':
                _stats.processing.datasets[1].data[dateIdx] += s.counter
                usedProcessing += s.counter;
                break;
              case 'App\\Models\\EntityType':
                _stats.storage.datasets[0].data[dateIdx] += s.counter
                break;
              case 'App\\Models\\Field':
                _stats.storage.datasets[1].data[dateIdx] += s.counter
                break;
            }
          });
          _stats.storage.labels.forEach((l, i) => {
            let dayUsage = _stats.storage.datasets[0].data[i] + _stats.storage.datasets[1].data[i];
            if (dayUsage > usedStorage) {
              usedStorage = dayUsage;
            }
          })
        }
        let subs = {};
        if (r && r.hasOwnProperty('subscriptions')) {
          r.subscriptions.forEach(sub => {
            subs[sub.id] = sub;
          })
        }
        setUsage({
          process: {
            total: usedProcessing,
            percent: usedProcessing / subs[subP.subscription_id].limit * 100,
            limit: subs[subP.subscription_id].limit,
          },
          storage: usedStorage
        });
        setStats(_stats);
        setIsLoading(false);
      });
    }
  }, [siteSubscriptions]);

  if (!siteSubscriptions?.length)
    return <Grid container spacing={2} alignItems="center" justifyContent="center" direction="column" style={{height:'calc(100vh - 108px)'}}>
      <Grid item><Typography variant="h2">{intl.formatMessage({
        id: "usage.no-subscriptions",
        defaultMessage: "It looks like you don't have any subscriptions, please goto {billing} to select your subscriptions."
      }, {billing:intl.formatMessage({id:"menu.billing.list"})})}</Typography></Grid>
      <Grid item><Button variant="contained" color="primary" onClick={_ => navigate("/admin/billing")}>{intl.formatMessage({id:"menu.billing.list"})}</Button></Grid>
    </Grid>;
  else if (isLoading) {
    return <Grid container spacing={2} alignItems="center" justifyContent="center" direction="column" style={{height:'calc(100vh - 108px)'}}>
      <Grid item><Typography variant="h2">{intl.formatMessage({id:"usage.loading", defaultMessage: "Analyzing data, please wait..."})}</Typography></Grid>
      <Grid item><CircularProgress/></Grid>
    </Grid>
  }
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "usage.processing.title",
            defaultMessage: "Processing usage since {date}"
          }, {
            date: moment(subProcess.renewed_at).format(intl.formatMessage({id: "common.date.format"}))
          })}
        ></CardHeader>
        <CardContent>
          <Typography>
            <FormattedMessage
              id="usage.processing.accumulated"
              defaultMessage="Your accumulated processing usage since last renewal {total, number}, as a percentage to your subscriptions limit of {limit, number}"
              values={usage.process}
            />
          </Typography>
          <Box display="flex" alignItems="center">
            {/*<Box flexGrow={2} whiteSpace="nowrap" mr={1}></Box>*/}
            <Box width="100%" mr={1}>
              <LinearProgress
                style={{margin: 0}}
                color={usage.process.percent >= 100 ? "secondary" : "primary"}
                value={usage.process.percent > 100 ? 100 : usage.process.percent}
                variant={"determinate"}
              />
            </Box>
            <Box flexGrow={1}>{Math.round(usage.process.percent)}%</Box>
          </Box>
          <Typography><FormattedMessage id="usage.processing.graphs"
                                        defaultMessage="Your processing usage per day, per area of use."/></Typography>
          <Box position="relative" height={285}>
            <Line
              data={stats.processing}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                  padding: 0,
                },
                elements: {
                  point: {
                    radius: 5,
                  },
                },
                tooltips: {
                  enabled: true,
                  mode: "index",
                  intersect: false,
                },
                plugins: {
                  tooltip: {
                    mode: "index",
                  },
                  legend: {
                    display: false,
                    position: "top",
                  },
                },
                scales: {
                  y:
                    {
                      min: 0,
                    },
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardHeader
          title={intl.formatMessage({id: "usage.storage.title", defaultMessage: "Storage usage since {date}"}, {
            date: moment(subStorage.renewed_at).format(intl.formatMessage({id: "common.date.format"}))
          })}
        ></CardHeader>
        <CardContent>
          <Typography><FormattedMessage id="usage.storage.highest_this_month"
                                        defaultMessage="Your highest storage use since last renewal is: {value, number}"
                                        values={{value: usage.storage}}/></Typography>
          <Typography><FormattedMessage id="usage.storage.graphs"
                                        defaultMessage="Your storage usage per day, per area of use."/></Typography>
          <Box position="relative" height={285}>
            <Line
              data={stats.storage}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                  padding: 0,
                },
                elements: {
                  point: {
                    radius: 5,
                  },
                },
                tooltips: {
                  enabled: true,
                  mode: "index",
                  intersect: false,
                },
                plugins: {
                  tooltip: {
                    mode: "index",
                  },
                  legend: {
                    display: false,
                    position: "top",
                  },
                },
                scales: {
                  y: {
                    min: 0,
                  },
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}
